/**
 * Contact Utilities
 *
 * This file contains constants and utility functions used in the contact components.
 *
 * @module contactUtils
 */

import { toast } from 'react-toastify';

export const INITIAL_FORM_STATE = {
  name: '',
  email: '',
  company: '',
  phnNo: '',
  message: '',
};

export const VALIDATION_REGEX = {
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  companyName: /^[a-zA-Z0-9\s]+$/,
  fullName: /^[A-Za-z ]+$/,
  phone: /^\d{10}$/,
};

/**
 * Validates the form data
 * @param {Object} formData - The form data to validate
 * @returns {boolean} True if form is valid, false otherwise
 */
export const validateForm = (formData) => {
  const { name, email, company, phnNo, message } = formData;

  if (!name || !email || !message || !phnNo || !company) {
    toast.error('Please fill all the required fields.');
    return false;
  }

  if (!VALIDATION_REGEX.fullName.test(name.trim())) {
    toast.error('Please enter a valid full name.');
    return false;
  }

  if (!VALIDATION_REGEX.email.test(email.trim())) {
    toast.error('Please enter a valid email address.');
    return false;
  }

  if (!VALIDATION_REGEX.companyName.test(company.trim())) {
    toast.error('Please enter a valid company name.');
    return false;
  }

  if (phnNo && !VALIDATION_REGEX.phone.test(phnNo)) {
    toast.error('Please enter a valid 10-digit phone number.');
    return false;
  }

  if (!message.trim()) {
    toast.error('Please enter your message.');
    return false;
  }

  return true;
};
