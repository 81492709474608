import { httpsCallable } from "firebase/functions";
import { CloudFunctions } from "../utils/firebase/firebase";

export const validateAuthCodeAndGetDoctor = async (authCode, uid) => {
  try {
    const validateAuthCode = httpsCallable(CloudFunctions, "validateAuthCode");

    const response = await validateAuthCode({
      authCode,
      uid,
    });

    if (response.data.success) {
      return response.data.data;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const patientLogin = async (uid) => {
  try {
    const validatePatientLogin = httpsCallable(
      CloudFunctions,
      "validatePatientLogin"
    );

    const response = await validatePatientLogin({
      uid,
    });
    if (response.data.status) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const doctorLogin = async (uid) => {
  try {
    const validateDoctorLogin = httpsCallable(
      CloudFunctions,
      "validateDoctorLogin"
    );

    const response = await validateDoctorLogin({
      uid,
    });
    if (response.data.status) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const updatePatientProfileDetails = async (details, uid) => {
  try {
    const updatePatientProfile = httpsCallable(
      CloudFunctions,
      "updatePatientProfile"
    );

    const response = await updatePatientProfile({
      details,
      uid,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const editDoctorFeedbackTreatments = async (
  uid,
  treatments,
  patientId,
  videoId
) => {
  try {
    const updateDoctorFeedbackTreatments = httpsCallable(
      CloudFunctions,
      "updateDoctorFeedbackTreatments"
    );

    const response = await updateDoctorFeedbackTreatments({
      uid,
      treatments,
      patientId,
      videoId,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const editDoctorFeedbackBehaviours = async (
  uid,
  behaviours,
  patientId,
  videoId
) => {
  try {
    const updateDoctorFeedbackBehaviours = httpsCallable(
      CloudFunctions,
      "updateDoctorFeedbackBehaviours"
    );

    const response = await updateDoctorFeedbackBehaviours({
      uid,
      behaviours,
      patientId,
      videoId,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const updateDoctorProfileDetails = async (details, uid) => {
  try {
    const updateDoctorProfile = httpsCallable(
      CloudFunctions,
      "updateDoctorProfile"
    );

    const response = await updateDoctorProfile({
      details,
      uid,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getPatientVideos = async (uid, category) => {
  try {
    const getMyVideos = httpsCallable(CloudFunctions, "getMyVideos");

    const response = await getMyVideos({
      uid,
      category,
    });
    if (response.data.status) {
      return response.data.videos;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getAllPatientVideos = async (uid) => {
  try {
    const getAllPatientVideos = httpsCallable(
      CloudFunctions,
      "getAllPatientVideos"
    );

    const response = await getAllPatientVideos({
      uid,
    });
    if (response.data.status) {
      return response.data.videos;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchVideoDetails = async (uid, videoId) => {
  try {
    const getVideoDetailsForPatient = httpsCallable(
      CloudFunctions,
      "getVideoDetailsForPatient"
    );

    const response = await getVideoDetailsForPatient({
      uid,
      videoId,
    });
    if (response.data.status) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getMyPatientsDetails = async (uid) => {
  try {
    const getMyPatients = httpsCallable(CloudFunctions, "getMyPatients");

    const response = await getMyPatients({
      uid,
    });
    if (response.data.status) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchPatientTreatments = async (uid) => {
  try {
    const getPatientTreatments = httpsCallable(
      CloudFunctions,
      "getPatientTreatments"
    );

    const response = await getPatientTreatments({
      uid,
    });
    if (response.data.status) {
      return response.data.treatments;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const UpdatePatientNotesToVault = async (notes, uid, videoId) => {
  try {
    const updatePatientFeedbackNotes = httpsCallable(
      CloudFunctions,
      "updatePatientFeedbackNotes"
    );

    const response = await updatePatientFeedbackNotes({
      notes,
      uid,
      videoId,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const UpdatedDoctorFeedbackNotesToVault = async (
  notes,
  uid,
  patientId,
  videoId
) => {
  try {
    const updateDoctorFeedbackNotes = httpsCallable(
      CloudFunctions,
      "updateDoctorFeedbackNotes"
    );

    const response = await updateDoctorFeedbackNotes({
      notes,
      uid,
      patientId,
      videoId,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getAllVideosFeedbackData = async (uid) => {
  try {
    const getAllDocNotesForPatient = httpsCallable(
      CloudFunctions,
      "getAllDocNotesForPatient"
    );

    const response = await getAllDocNotesForPatient({
      uid,
    });
    if (response.data.status) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchProcessedVideoDetails = async (uid, videoId) => {
  try {
    const getProcessedVideoInfo = httpsCallable(
      CloudFunctions,
      "getProcessedVideoInfo"
    );

    const response = await getProcessedVideoInfo({
      uid,
      videoId,
    });
    if (response.data.status) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchUserVideoActivityLogs = async (uid, videoId) => {
  try {
    const getUserVideoActivityLogs = httpsCallable(
      CloudFunctions,
      "getUserVideoActivityLogs"
    );

    const response = await getUserVideoActivityLogs({
      uid,
      videoId,
    });
    // console.log("Fetching video activity logs", response);
    if (response.data.status) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchUserNotificationMessages = async (uid, videoId) => {
  try {
    const getUserNotificationMessages = httpsCallable(
      CloudFunctions,
      "getUserNotificationMessages"
    );

    const response = await getUserNotificationMessages({
      uid,
    });
    if (response.data.status) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const deleteUserAccountInfo = async (uid, typeOfUser) => {
  try {
    const deleteUserAccount = httpsCallable(
      CloudFunctions,
      "deleteUserAccount"
    );

    const response = await deleteUserAccount({
      uid,
      typeOfUser,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const updatePatientTreatmentDetails = async (
  uid,
  patientId,
  treatmentId,
  treatment
) => {
  try {
    const updatePatientTreatmentInfo = httpsCallable(
      CloudFunctions,
      "updatePatientTreatmentInfo"
    );

    const response = await updatePatientTreatmentInfo({
      uid,
      patientId,
      treatmentId,
      treatment,
    });
    if (response.data.status) {
      return response.data.message;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchDoctorAndPatientMessages = async (
  uid,
  doctorId,
  patientId
) => {
  try {
    const getDoctorAndPatientMessages = httpsCallable(
      CloudFunctions,
      "getDoctorAndPatientMessages"
    );

    const response = await getDoctorAndPatientMessages({
      uid,
      doctorId,
      patientId,
    });
    console.log(" response at api", response);
    if (response?.data?.status) {
      return response?.data?.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const addPatientByDoctor = async (uid, patientEmail) => {
  try {
    const addExistingPatientByDoctor = httpsCallable(
      CloudFunctions,
      "addExistingPatientByDoctor"
    );

    const response = await addExistingPatientByDoctor({
      uid,
      patientEmail,
    });
    console.log("response: ", response);

    if (response.data.status) {
      return response.data;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const fetchAllAttachments = async (uid) => {
  try {
    const getAllAttachmentsData = httpsCallable(
      CloudFunctions,
      "getAllAttachmentsData"
    );

    const response = await getAllAttachmentsData({
      uid,
    });
    console.log("response: ", response);

    if (response.data.status) {
      return response.data;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchAllAttachmentsByUser = async (uid, typeOfUser) => {
  try {
    const getAllAttachmentsByUser = httpsCallable(
      CloudFunctions,
      "getAllAttachmentsByUser"
    );

    const response = await getAllAttachmentsByUser({
      uid,
      typeOfUser,
    });

    if (response.data.status) {
      return response.data;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const fetchGroupMessages = async (uid, groupID) => {
  try {
    const getGroupMessages = httpsCallable(CloudFunctions, "getGroupMessages");

    const response = await getGroupMessages({
      uid,
      groupID,
    });
    console.log(" at api : ", response);
    if (response.data.status) {
      return response.data.data;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};
