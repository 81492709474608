import { Delete } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from "@mui/material";
import React from "react";

const DeleteModel = ({ videoId, patientId, videoFileName, handleDelete }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="delete">
        <Button onClick={handleClickOpen}>
          <Delete sx={{ color: "red" }} />
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: "bold" }}>
          {`Are you sure you want to delete "${videoFileName}"?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This video will be deleted permanentely. You can't undo this action.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="btn" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="btn"
            onClick={() => {
              handleDelete();
              handleClose();
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteModel;
