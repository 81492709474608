import { Person } from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import {
  Button,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  FirebaseAuth,
  LogAnalyticsEvent,
} from "../../../utils/firebase/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { ANALYTICS_KEYS } from "../../../constants/AnalyticKeys";

const ForgotPassword = ({ signinHandler }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(false);

  const validateInputs = () => {
    return email.trim().length > 0;
  };

  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const forgotPasswordHandler = (email) => {
    if (validateInputs()) {
      sendPasswordResetEmail(FirebaseAuth, email)
        .then(() => {
          setMessage("success");
        })
        .catch((error) => {
          const errorMessage = error.message;
          setMessage("error");
        });
      LogAnalyticsEvent(ANALYTICS_KEYS.USERS.FORGOTPASSWORD, {
        message: "Initiated a password reset request",
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 20,
        height: "100%",
        width: "100%",
        textAlign: "center",
        marginTop: "20%",
      }}
    >
      <Typography variant="h3" sx={{ fontFamily: "Lato", fontWeight: 700 }}>
        Enter your email
      </Typography>
      <Typography
        variant="h6"
        color={"GrayText"}
        sx={{ fontFamily: "Karla", fontWeight: 700, letterSpacing: "0px" }}
      >
        Submit to continue
      </Typography>
      <TextField
        label="Email"
        type={"email"}
        sx={{ m: 4 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Person />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        onChange={emailChangeHandler}
      />
      {message === "success" && (
        <Alert severity="success">Password reset email has been sent.</Alert>
      )}
      {message === "error" && (
        <Alert severity="error">
          Invalid: Check your email address correctly.
        </Alert>
      )}
      <Button
        sx={{
          background: "#9291f0",
          textTransform: "capitalize",
          fontFamily: "Lato",
          fontWeight: 400,
          fontSize: "1rem",
        }}
        fullWidth
        className="custom-button"
        variant="contained"
        onClick={() => {
          forgotPasswordHandler(email);
        }}
      >
        Submit
      </Button>
      <Typography
        sx={{
          fontFamily: "Karla",
          fontWeight: 700,
          fontSize: "1rem",
          color: "var(--clr-theme-gray)",
        }}
      >
        Already have an account ?
        <Link
          onClick={signinHandler}
          style={{ marginLeft: "5px", cursor: "pointer" }}
        >
          Sign In
        </Link>
      </Typography>
    </div>
  );
};

export default ForgotPassword;
