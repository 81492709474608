import {
  Box,
  ListItemText,
  Typography,
  TextField,
  Card,
  CardContent,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  tooltipClasses,
  styled,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { collection, doc, updateDoc, onSnapshot } from "firebase/firestore";

import { FirestoreDB, LogAnalyticsEvent } from "../../utils/firebase/firebase";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import AuthContext from "../../contexts/AuthContext";
import UpdateTreatments from "../../components/UpdateTreatments/index.js";
import "./PatientOverview.css";
import { updatePatientProfileDetails } from "../../api";
import DataProvider, { DataContext } from "../../contexts/DataProvider";
import { Chat, Close, West } from "@mui/icons-material";
import Chatconversation from "../../components/Chat";
import { useNavigate } from "react-router-dom";
import Reminders from "../../components/Reminders/index.js";
import { ANALYTICS_KEYS } from "../../constants/AnalyticKeys.js";

const No_Photo_User = "https://cdn-icons-png.flaticon.com/512/456/456212.png";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: 'bold',
    padding: '10px',
  },
}));

const PatientDetails = ({ patient }) => {
  const { setOpenRightPanel, setPanelContent, user } = useContext(AuthContext);
  const {
    currentPatientData,
    fetchOnePatientData,
    setCurrentPatientData,
    patientData,
    setPatientData,
  } = useContext(DataContext);

  const [currentPatient, setCurrentPatient] = useState({
    ...patient,
    diagnosis: patient.diagnosis?.join(", ") || "",
  });
  const [setTreatments] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);

    LogAnalyticsEvent(ANALYTICS_KEYS.PATIENTSLIST.REMINDER, {
      button_name: "Set Reminder",
      message: "Navigated to reminders screen"
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  console.log("currentPatient: ", currentPatient);

  const navigateToChatScreen = async (notificationDetails) => {
    navigate("/message", {
      state: { patientId: currentPatient?.id },
    });
    LogAnalyticsEvent(ANALYTICS_KEYS.PATIENTSLIST.MESSAGE,{
      patient_id : currentPatient?.id,
      message: "Navigated to chat screen"
    })
  };

  const ChatconversationHandler = () => {
    setPanelContent(<Chatconversation patient={patient} />);
    setOpenRightPanel(true);
  };

  const UpdateDoctorChanges = async () => {
    // const patientRef = doc(FirestoreDB, "patients", patient?.uid);
    const params = {
      fullname: currentPatient?.fullname || "",
      dob: currentPatient?.dob || null,
    };

    const upadtedProfile = { ...currentPatientData?.user, ...params };

    setCurrentPatientData((prev) => {
      return {
        ...prev,
        user: upadtedProfile,
      };
    });

    await updatePatientInfo(patient.id, upadtedProfile);
    const result = await updatePatientProfileDetails(
      btoa(JSON.stringify(params)),
      patient.id
    );
    setEditOpen(false);
  };

  const updatePatientInfo = async (patientId, updatedProfile) => {
    // Find the index of the patient in the patientData array
    const index = patientData.findIndex(
      (patient) => patient.patientId === patientId
    );

    // If the patient is found, update the patientInfo with the updatedProfile
    if (index !== -1) {
      setPatientData((prevPatientData) => {
        const newPatientData = [...prevPatientData]; // Create a copy of the patientData array
        newPatientData[index] = {
          ...newPatientData[index], // Copy the existing patient
          patientInfo: {
            ...newPatientData[index].patientInfo, // Copy the existing patientInfo
            ...updatedProfile, // Update with the new profile data
          },
        };
        return newPatientData;
      });
    } else {
      console.error(`Patient with ID ${patientId} not found.`);
    }
  };

  const ClearChanges = () => {
    setCurrentPatient(patient);
    setEditOpen(false);
  };

  const validateString = (str) => {
    return str?.toString().trim() || "-";
  };

  useEffect(() => {
    if (patient?.id !== currentPatientData?.id) {
      fetchOnePatientData(patient.id);
    }
    setCurrentPatient({
      ...patient,
      diagnosis: patient.diagnosis?.join(", ") || "",
    });
    // getPatientTreatments();
    setEditOpen(false);
  }, [patient]);
  
  const truncateName = (name, maxLength = 10) => {
    return name?.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
  };

  return (
    <Card
      className="patientDetailsCard"
      sx={{ justifyContent: "space-between", boxShadow: "none" }}
    >
      <CardContent className="imagePosition">
        <img
          src={currentPatient?.photoURL || No_Photo_User}
          alt="Avatar"
          style={{
            width: "20vh",
            height: "20vh",
            borderRadius: "20px 20px 0px 0px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            margin: "0.5rem auto",
          }}
        />
        <div className="container">
          {!editOpen ? (
            <>
              <Typography
                variant="h4"
                sx={{ fontFamily: "Lato", fontWeight: 700 }}
              >
                <BootstrapTooltip
    title={
      currentPatient.fullname
        ? currentPatient.fullname.split(/\s+/).map(
            (word) =>
              word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          ).join(" ")
        : ""
    }
  >
    <span>
      {currentPatient.fullname
        ? truncateName(
            currentPatient.fullname.split(/\s+/).map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            ).join(" ")
          )
        : ""}
    </span>
  </BootstrapTooltip>
              </Typography>

              {/* <Typography>
                {currentPatient?.dob?.seconds || currentPatient?.dob ? (
                  <ListItemText
                    primary={validateString(
                      new Date(
                        currentPatient?.dob?.seconds * 1000 ||
                        currentPatient?.dob
                      ).toLocaleDateString()
                    )}
                    secondary="Date of birth"
                  />
                ) : (
                  <ListItemText
                    primary={validateString("-")}
                    secondary="Date of birth"
                  />
                )}
              </Typography> */}
            </>
          ) : (
            <>
              {/* <TextField
                fullWidth
                label="Full Name"
                value={currentPatient?.fullname}
                onChange={(e) => {
                  setCurrentPatient({
                    ...currentPatient,
                    fullname: e.target.value,
                  });
                }}
              /> */}
              {/* <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Date of Birth"
                  value={
                    currentPatient?.dob?.seconds * 1000 || currentPatient?.dob
                  }
                  onChange={(newValue) => {
                    setCurrentPatient({
                      ...currentPatient,
                      dob: newValue._d,
                    });
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider> */}
            </>
          )}
        </div>
      </CardContent>
      <Box>
        {!editOpen ? (
          <CardActions
            className="flexBox flexColumn "
            sx={{ gap: 1, alignItems: "center" }}
          >
            <Button
              color="primary"
              variant="contained"
              sx={{
                backgroundColor: "var(--clr-theme-purple) !important",
                width: "50%",
                ml: 1,
              }}
              onClick={navigateToChatScreen}
            >
              Chat <Chat sx={{ ml: 1, fontSize: 16 }} />
            </Button>
            <Button
              sx={{
                backgroundColor: "var(--clr-theme-purple) !important",
                width: "50%",
                textTransform: "capitalize",
                color: "white",
              }}
              onClick={handleClickOpen}
            >
              Set Reminder
            </Button>
            <Dialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
              maxWidth="lg"
            >
              <AppBar
                sx={{
                  position: "relative",
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
              >
                <Toolbar>
                  {/* <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <West sx={{ color: "black", fontSize: "30px" }} />
                  </IconButton> */}
                  <Typography
                    sx={{
                      flex: 1,
                      color: "black",
                      fontSize: "1.8rem",
                      fontFamily: "Lato",
                    }}
                    variant="h6"
                    component="div"
                  >
                    Reminders
                  </Typography>
                  <Button autoFocus color="inherit" onClick={handleClose}>
                    <Close sx={{ color: "black" }} />
                  </Button>
                </Toolbar>
              </AppBar>
              <DialogContent sx={{ width: "42vw" }}>
                <Reminders patient={patient} />
              </DialogContent>
            </Dialog>
            {/* <Button
              color="primary"
              variant="contained"
              sx={{
                backgroundColor: "var(--clr-theme-purple) !important",
                width: "50%",
              }}
              onClick={() => {
                setEditOpen(true);
              }}
            >
              Edit Profile
            </Button> */}
          </CardActions>
        ) : (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                width: "48%",
                fontFamily: "Lato",
                textTransform: "capitalize",
              }}
              onClick={ClearChanges}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              sx={{
                backgroundColor: "var(--clr-theme-purple) !important",
                width: "48%",
                fontFamily: "Lato",
                textTransform: "capitalize",
              }}
              onClick={UpdateDoctorChanges}
            >
              Save
            </Button>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default PatientDetails;
