import {
  Box,
  FormControlLabel,
  Typography,
  CardActions,
  Button,
  TextField,
  Card,
  CardContent,
  tooltipClasses,
  styled,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import "./PatientOverview.css";
import { updatePatientProfileDetails } from "../../api";
import Progress from "../../components/Progress";
import { DataContext } from "../../contexts/DataProvider";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: 'bold',
    padding: '10px',
  },
}));

function PatientInformation({ patient, setPatient }) {
  const [currentPatient, setCurrentPatient] = useState({
    ...patient,
    diagnosis: patient.diagnosis?.join(", ") || "",
  });
  const [editOpen, setEditOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    currentPatientData,
    fetchOnePatientData,
    setCurrentPatientData,
    patientData,
    setPatientData,
  } = useContext(DataContext);

  const validateString = (str) => {
    return str?.toString().trim() || "-";
  };

  const UpdateDoctorChanges = async () => {
    const params = {
      age: validateString(
        currentPatient?.dob?.seconds
          ? Math.floor(
              (new Date() - new Date(currentPatient?.dob?.seconds * 1000)) /
                (1000 * 3600 * 24 * 365)
            ) || "-"
          : Math.floor(
              (new Date() - new Date(currentPatient?.dob)) /
                (1000 * 3600 * 24 * 365)
            ) || "-"
      ),
      dob: currentPatient.dob || null,
      fullname: currentPatient.fullname || "",
      gender: currentPatient.gender || "",
      blood: currentPatient.blood || "",
      height: currentPatient.height || "",
      currentWeight: currentPatient.currentWeight || "",
      contactNo: currentPatient.contactNo || null,
      email: currentPatient.email || "",
    };
    const upadtedProfile = { ...currentPatientData.user, ...params };

    setCurrentPatientData((prev) => {
      return {
        ...prev,
        user: upadtedProfile,
      };
    });

    setPatient((prev) => {
      return {
        ...prev,
        ...upadtedProfile,
      };
    });

    await updatePatientInfo(patient.id, upadtedProfile);

    const result = await updatePatientProfileDetails(
      btoa(JSON.stringify(params)),
      patient.id
    );
    setIsLoading(false);
    setEditOpen(false);
  };

  const updatePatientInfo = async (patientId, updatedProfile) => {
    // Find the index of the patient in the patientData array
    const index = patientData.findIndex(
      (patient) => patient.patientId === patientId
    );

    // If the patient is found, update the patientInfo with the updatedProfile
    if (index !== -1) {
      setPatientData((prevPatientData) => {
        const newPatientData = [...prevPatientData]; // Create a copy of the patientData array
        newPatientData[index] = {
          ...newPatientData[index], // Copy the existing patient
          patientInfo: {
            ...newPatientData[index].patientInfo, // Copy the existing patientInfo
            ...updatedProfile, // Update with the new profile data
          },
        };
        return newPatientData;
      });
    } else {
      console.error(`Patient with ID ${patientId} not found.`);
    }
  };

  const truncateName = (name, maxLength = 10) => {
    return name?.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
  };

  const ClearChanges = () => {
    setCurrentPatient(patient);
    setEditOpen(false);
  };

  useEffect(() => {
    if (patient?.id !== currentPatientData?.id) {
      fetchOnePatientData(patient.id);
    }
    setCurrentPatient({
      ...patient,
      diagnosis: patient.diagnosis?.join(", ") || "",
    });
  }, [patient, currentPatientData]);
  return (
    <Card className="patientInformationCard" sx={{ boxShadow: "none" }}>
      <div className="patientInformation">
        <CardContent
          className="flexBox flexWrap"
          sx={{
            justifyContent: "left",
            flexDirection: "row",
            width: "100%",
            height: "100%",
          }}
        >
          <Box className="patientInformationBox">
            {!editOpen ? (
              <div style={{ textAlign: "left" }}>
                <>
                  <div style={{ paddingBottom: "8px" }}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      align="left"
                      fontSize={14}
                      color="rgba(0, 0, 0, 0.6)"
                    >
                      Parent Name/Guardian
                    </Typography>
                    <Typography variant="body2" align="left" fontSize={16}>
                      <BootstrapTooltip
                        title={currentPatientData?.user?.parentName || "-"}
                      >
                        <span>
                          {currentPatientData?.user?.parentName
                            ? truncateName(currentPatientData.user.parentName)
                            : "-"}
                        </span>
                      </BootstrapTooltip>
                    </Typography>
                  </div>
                  <div style={{ paddingBottom: "8px" }}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      align="left"
                      fontSize={14}
                      color="rgba(0, 0, 0, 0.6)"
                    >
                      Email
                    </Typography>
                    <Typography
                      variant="body2"
                      align="left"
                      fontSize={16}
                      sx={{
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                        whiteSpace: "normal",
                        textAlign: "left",
                        lineHeight: "1",
                      }}
                    >
                      {currentPatientData?.user?.email || "-"}
                    </Typography>
                  </div>
                  <div style={{ paddingBottom: "8px" }}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      align="left"
                      fontSize={14}
                      color="rgba(0, 0, 0, 0.6)"
                    >
                      Gender
                    </Typography>
                    <Typography variant="body2" align="left" fontSize={16}>
                      {currentPatientData?.user?.gender || "-"}
                    </Typography>
                  </div>
                  {/* <FormControlLabel
                  control={
                    <Typography>
                      {currentPatientData?.user?.blood || "-"}
                    </Typography>
                  }
                  label="Blood Type:"
                  labelPlacement="start"
                /> */}
                  {/* <FormControlLabel
                  control={
                    <Typography>
                      {currentPatientData?.user?.height || "-"}
                    </Typography>
                  }
                  label="Height:"
                  labelPlacement="start"
                /> */}
                  {/* <FormControlLabel
                  control={
                    <Typography>
                      {currentPatientData?.user?.currentWeight || "-"}
                    </Typography>
                  }
                  label="Weight:"
                  labelPlacement="start"
                /> */}
                  <div style={{ paddingBottom: "8px" }}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      align="left"
                      fontSize={14}
                      color="rgba(0, 0, 0, 0.6)"
                    >
                      Contact no
                    </Typography>
                    <Typography variant="body2" align="left" fontSize={16}>
                      {`+${
                        currentPatientData?.user?.contactNo?.areaCode || "00"
                      }-${
                        currentPatientData?.user?.contactNo?.number ||
                        "00000000"
                      }`}
                    </Typography>
                  </div>
                  <div style={{ paddingBottom: "8px" }}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      align="left"
                      fontSize={14}
                      color="rgba(0, 0, 0, 0.6)"
                    >
                      Date of Birth
                    </Typography>
                    <Typography variant="body2" align="left" fontSize={16}>
                      {currentPatientData?.user?.dob &&
                        validateString(
                          new Date(
                            currentPatientData?.user?.dob.seconds * 1000 ||
                              currentPatientData?.user?.dob
                          ).toLocaleDateString()
                        )}
                    </Typography>
                  </div>
                  <div style={{ paddingBottom: "8px" }}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      align="left"
                      fontSize={14}
                      color="rgba(0, 0, 0, 0.6)"
                    >
                      Age
                    </Typography>
                    <Typography variant="body2" align="left" fontSize={16}>
                      {validateString(
                        (() => {
                          const dobInMillis = patient?.user?.dob?.seconds
                            ? patient.user.dob.seconds * 1000
                            : new Date(patient?.dob).getTime();
                          const ageInMillis =
                            new Date() - new Date(dobInMillis);
                          const ageInYears =
                            ageInMillis / (1000 * 3600 * 24 * 365);
                          return ageInYears < 1 ? 0 : Math.floor(ageInYears);
                        })()
                      )}
                    </Typography>
                  </div>
                </>
              </div>
            ) : (
              <>
                <TextField
                  sx={{ width: "93%" }}
                  label="Full Name"
                  value={currentPatient?.fullname}
                  onChange={(e) => {
                    setCurrentPatient({
                      ...currentPatient,
                      fullname: e.target.value,
                    });
                  }}
                />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Date of Birth"
                    value={currentPatient?.dob || null}
                    onChange={(newValue) => {
                      setCurrentPatient({
                        ...currentPatient,
                        dob: newValue._d,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField sx={{ width: "93%" }} {...params} />
                    )}
                  />
                </LocalizationProvider>
                <TextField
                  sx={{ width: "93%" }}
                  label="Gender"
                  value={currentPatient?.gender}
                  onChange={(e) => {
                    setCurrentPatient({
                      ...currentPatient,
                      gender: e.target.value,
                    });
                  }}
                />
                {/* <TextField
                  sx={{ width: "93%" }}
                  label="Blood Type"
                  value={currentPatient?.blood}
                  onChange={(e) => {
                    setCurrentPatient({
                      ...currentPatient,
                      blood: e.target.value,
                    });
                  }}
                /> */}
                {/* <TextField
                  sx={{ width: "93%" }}
                  label="Height"
                  value={currentPatient?.height}
                  onChange={(e) => {
                    setCurrentPatient({
                      ...currentPatient,
                      height: e.target.value,
                    });
                  }}
                /> */}
                {/* <TextField
                  sx={{ width: "93%", justifyContent: "center" }}
                  label="Weight"
                  value={currentPatient?.currentWeight}
                  onChange={(e) => {
                    setCurrentPatient({
                      ...currentPatient,
                      currentWeight: e.target.value,
                    });
                  }}
                /> */}
                <Box className="flexBox" sx={{ width: "93%", gap: 1 }}>
                  <TextField
                    sx={{ width: "45%" }}
                    label="Area Code"
                    value={currentPatient?.contactNo?.areaCode}
                    onChange={(e) => {
                      setCurrentPatient({
                        ...currentPatient,
                        contactNo: {
                          ...currentPatient.contactNo,
                          areaCode: Number(e.target.value.trim().slice(0, 3)),
                        },
                      });
                    }}
                  />
                  <TextField
                    fullWidth
                    // sx={{ width: "93%" }}
                    label="Number"
                    value={currentPatient?.contactNo?.number}
                    onChange={(e) => {
                      setCurrentPatient({
                        ...currentPatient,
                        contactNo: {
                          ...currentPatient.contactNo,
                          number: Number(e.target.value.trim().slice(0, 10)),
                        },
                      });
                    }}
                  />
                </Box>

                <TextField
                  sx={{ width: "93%" }}
                  label="Email"
                  padding="50px"
                  value={currentPatient?.email}
                  onChange={(e) => {
                    setCurrentPatient({
                      ...currentPatient,
                      email: e.target.value,
                    });
                  }}
                />
              </>
            )}
          </Box>
        </CardContent>
      </div>
      {/* <Box>
        {!editOpen ? (
          <CardActions className="flexBox flexColumn">
            <Button
              color="primary"
              variant="contained"
              sx={{ backgroundColor: "var(--clr-theme-purple) !important" }}
              onClick={() => {
                setEditOpen(true);
              }}
            >
              Edit Information
            </Button>
          </CardActions>
        ) : (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                width: "48%",
                fontFamily: "Lato",
                textTransform: "capitalize",
              }}
              onClick={ClearChanges}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              sx={{
                backgroundColor: "var(--clr-theme-purple) !important",
                width: "48%",
                fontFamily: "Lato",
                textTransform: "capitalize",
              }}
              onClick={UpdateDoctorChanges}
            >
              Update
            </Button>
          </Box>
        )}
      </Box> */}
      {isLoading && <Progress />}
    </Card>
  );
}

export default PatientInformation;
