import {
  PlayArrow,
  PlayCircle,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { collection, doc, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataProvider";

import { UIContext } from "../../contexts/UIProvider";
import { FirestoreDB, LogAnalyticsEvent } from "../../utils/firebase/firebase";
import PlayVideo from "../PlayVideo";
import { ANALYTICS_KEYS } from "../../constants/AnalyticKeys";

const VideoListPatient = ({ patient, typeOfUser }) => {
  const { setBottomDrawer } = useContext(UIContext);

  const { fetchOnePatientAllVideos, currentPatientVideos, isLoading } =
    useContext(DataContext);
  const [videoList, setVideoList] = useState([]);
  const [docNotes, setDocNotes] = useState([]);

  const getVideoLogs = async () => {
    // * Get my own videos
    const docNotes = [];
    onSnapshot(
      collection(FirestoreDB, "videoLogs", patient?.id, "rawVideos"),
      (videoRef) => {
        const videosList = videoRef.docs
          .map((doc) => {
            return { ...doc.data(), id: doc.id };
          })
          .sort((a, b) => {
            return b.time.seconds - a.time.seconds;
          });

        setVideoList(
          videosList.map((v) => {
            return {
              ...v,
              user: { ...patient },
            };
          })
        );
      }
    );

    const docNotesQuery = collection(
      FirestoreDB,
      "videoLogs",
      patient?.id,
      "docNotes"
    );
    onSnapshot(docNotesQuery, (docNotesSnapshot) => {
      for (let j = 0; j < docNotesSnapshot?.docs?.length; j++) {
        docNotes.push(docNotesSnapshot.docs[j]?.id);
      }
      setDocNotes(docNotes);
    });
  };

  const handleVideoOpen = (videoDetails, patientID) => {
    setBottomDrawer({
      show: true,
      title: videoDetails?.rawVideoData?.fileName,
      patientName: videoDetails?.user?.fullname,
      patientPhoto: videoDetails?.user?.photoURL,
      content: (
        <PlayVideo
          videoList={videoList}
          handleVideoOpen={handleVideoOpen}
          videoDetails={videoDetails}
          patientID={patientID}
          typeOfUser={typeOfUser}
        />
      ),
    });
    LogAnalyticsEvent(ANALYTICS_KEYS.PATIENTOVERVIEW.VIDEOVIEW, {
      eventName: "Patient list video Opened",
      videoID: videoDetails?.videoId,
      patientID: patientID || "unknown_patient",
    });
  };

  useEffect(() => {
    if (patient?.id) {
      fetchOnePatientAllVideos(patient?.id);
    }
  }, [patient]);

  useEffect(() => {
    if (currentPatientVideos?.id) {
      setVideoList(
        currentPatientVideos?.data?.sort((a, b) => {
          return b.rawVideoData.time.seconds - a.rawVideoData.time.seconds;
        }).map((video) => {
          return {
            ...video,
            user: patient,
          };
        }) || []
      );
    }
  }, [currentPatientVideos]);

  return (
    isLoading ? (
      <Typography>Retrieving Video Data.</Typography>
    ) : videoList.length > 0 ? (
      <List className="newVideosList">
        {videoList.map((video, i) => (
          <ListItem
            key={video?.id || i}
            className="flexBox flexCenter listItemBox"
            sx={{ justifyContent: 'space-between', flexWrap: 'nowrap', overflow: 'hidden', }}
          >
            <ListItemAvatar>
              <Avatar
                variant="rounded"
                alt={patient?.fullname}
                src={patient?.photoURL}
              />
            </ListItemAvatar>
            <ListItemText
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                fontSize: '0.7rem',
              }}
              primary={
                <Typography className="username" variant="caption">
                  {video?.rawVideoData?.fileName
                    ? video.rawVideoData.fileName.split(/\s+/)
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                      .join(" ")
                    : ''}
                </Typography>
              }
              secondary={video?.patientNotes?.symptom}
            />
            <Box className="flexBox flexCenter">
              <ListItemIcon >
                {video?.docNotesDocId ? (
                  <VisibilityOutlined fontSize="small" color="success" />
                ) : (
                  <VisibilityOffOutlined fontSize="small" color="error" />
                )}
              </ListItemIcon>
              <ListItemText
                className="daysAgo"
                primary={
                  <Typography variant="caption" sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    fontSize: '0.7rem',
                  }}>
                    {`${Math.floor(
                      (new Date() - new Date(video?.rawVideoData?.time?.seconds * 1000)) /
                      (1000 * 3600 * 24)
                    )} days ago`}
                  </Typography>
                }
              />
              <Button
                onClick={() => {
                  handleVideoOpen(video, patient?.id);
                }}
              >
                <PlayCircle sx={{ color: "black", fontSize: "2.5rem" }} />
              </Button>
            </Box>
          </ListItem>
        ))}
      </List>
    ) : (
      <Typography>No new videos</Typography>
    )
  );


};

export default VideoListPatient;
