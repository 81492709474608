import React, { useContext, useEffect } from "react";
import { FirestoreDB } from "../../utils/firebase/firebase";
import { collection, query, where, onSnapshot, updateDoc, doc } from "firebase/firestore";
import { UIContext } from "../../contexts/UIProvider";

const UnreadMessageCounts = ({
  unreadCounts,
  setUnreadCounts,
  user,
  typeOfUser,
  patientData,
  selectedChat, // To identify the currently opened conversation
}) => {
  const { setIsNewMessage } = useContext(UIContext);

  useEffect(() => {
    const unsubscribes = [];
    const countsObj = {};

    const fetchUnreadCounts = () => {
      try {
        const userMessage=(typeOfUser==="DOCTOR")? "patientMessage":"doctorMessage";
        // Fetch unread messages
        const unreadMessagesQuery = query(
          collection(FirestoreDB, "notifications", user?.uid, "messages"),
          where("readStatus", "==", false),
          where("notificationSource", "==", userMessage)
        );
    
        const unsubscribe = onSnapshot(unreadMessagesQuery, (snapshot) => {
          const countsObj = {};
          snapshot.forEach((doc) => {
            const messageData = doc.data();
            const userId = messageData.userId; // ID of the sender    
            // Check if the message is sent by a patient or doctor
            let matchedUser;
            if (typeOfUser === "DOCTOR") {
              matchedUser = patientData.find((patient) => patient.patientId === userId);
            } else if (typeOfUser === "PATIENT") {
              matchedUser = user.doctorDetails.find((doctor) => doctor.doctorId === userId);
            }
    
            if (matchedUser) {
              const id = typeOfUser === "DOCTOR" ? matchedUser.patientId : matchedUser.doctorId;
              // Increment count for the corresponding user
              countsObj[id] = (countsObj[id] || 0) + 1;
            }
          });
    
          // Transform countsObj into the format required for unreadCounts
          const counts = Object.entries(countsObj).map(([id, count]) => ({
            [typeOfUser === "DOCTOR" ? "patientId" : "doctorId"]: id,
            count,
          }));

          const hasNewMessages = counts.some((item) => item.count > 0);
          setIsNewMessage(hasNewMessages);
          setUnreadCounts(counts);
        });
    
        unsubscribes.push(unsubscribe);
      } catch (error) {
        console.error("Error fetching unread message counts:", error);
      }
    };

    const markMessagesAsRead = async () => {
      // Check if selectedChat is valid
      if (!selectedChat) {
        console.warn("No chat selected.");
        return;
      }
    
      try {
        // Determine userId based on the type of user
        const userId = typeOfUser === "DOCTOR" ? selectedChat.patientId : selectedChat.doctorId;
    
        // Query to fetch unread messages for the selected chat
        const unreadMessagesQuery = query(
          collection(FirestoreDB, "notifications", user?.uid, "messages"),
          where("readStatus", "==", false),
          where("userId", "==", userId) // Fetch messages from the selected user based on userId
        );
    
        const unsubscribe = onSnapshot(unreadMessagesQuery, (snapshot) => {
          snapshot.forEach(async (docSnapshot) => {
            // Update each message's read status to true
            const messageRef = doc(FirestoreDB, "notifications", user?.uid, "messages", docSnapshot.id);
            await updateDoc(messageRef, { readStatus: true });
          });
    
          // Reset the unread count for this chat to zero
          setUnreadCounts((prevCounts) =>
            prevCounts.map((countObj) =>
              countObj[typeOfUser === "DOCTOR" ? "patientId" : "doctorId"] === userId
                ? { ...countObj, count: 0 }
                : countObj
            )
          );
        });
    
        unsubscribes.push(unsubscribe);
      } catch (error) {
        console.error("Error marking messages as read:", error);
      }
    };

    fetchUnreadCounts();

    // Automatically mark messages as read when the chat is opened
    if (selectedChat) {
      markMessagesAsRead();
    }

    // Cleanup subscriptions on component unmount
    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [user, typeOfUser, patientData, selectedChat]); // Listen for changes in selectedChat

  return null;
};

export default UnreadMessageCounts;
