import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getAnalytics, logEvent, isSupported } from "firebase/analytics";

import firebaseConfig from "./firebaseConfig.json";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const storage = getStorage(app);
const functions = getFunctions(app);
// const analytics = getAnalytics(app);

let analytics = null; // Declare analytics variable in the outer scope

// Check if Firebase Analytics is supported
isSupported()
  .then((supported) => {
    if (supported) {
      analytics = getAnalytics(app); // Initialize analytics if supported
      logEvent(analytics, "notification_received"); // Log an initial event
    } else {
      console.warn("Firebase Analytics is not supported in this environment.");
    }
  })
  .catch((error) => {
    console.error("Error checking analytics support:", error);
  });

const LogAnalyticsEvent = (event, params = {}) => {
  if (analytics) {
    logEvent(analytics, event, params);
  } else {
    console.log("Firebase Analytics is not initialized. Event not logged.");
  }
};

export {
  auth as FirebaseAuth,
  app as FirebaseInstance,
  db as FirestoreDB,
  storage as FirebaseStorage,
  functions as CloudFunctions,
  analytics as FirebaseAnalytics,
  LogAnalyticsEvent,
};
