import React, { useEffect, useState, useContext } from "react";
import { collection, doc, onSnapshot } from "firebase/firestore";
import {
  Box,
  Button,
  Typography,
  ListItem,
  List,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemIcon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  PlayCircle,
  Update,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import PlayVideo from "../PlayVideo/index";

import { UIContext } from "../../contexts/UIProvider";
import { FirestoreDB, LogAnalyticsEvent } from "../../utils/firebase/firebase";
import { DataContext } from "../../contexts/DataProvider";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { FaCalendarAlt } from "react-icons/fa";
import { ANALYTICS_KEYS } from "../../constants/AnalyticKeys";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ViewPatientAllVideos = ({ patient, typeOfUser }) => {
  const [videoList, setVideoList] = useState([]);
  const [docNotes, setDocNotes] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [filterOption, setFilterOption] = useState("All");
  const { bottomDrawer, setBottomDrawer } = useContext(UIContext);
  const { fetchOnePatientAllVideos, currentPatientVideos,isLoading } =
    useContext(DataContext);

  const getMyVideos = async () => {
    const docNotes = [];
    onSnapshot(
      collection(FirestoreDB, "videoLogs", patient?.id, "rawVideos"),
      (videoRef) => {
        const videosList = videoRef.docs
          .map((doc) => {
            return { ...doc.data(), id: doc.id };
          })         
          .sort((a, b) => {
            return (
              b?.rawVideoData.time?.seconds - a?.rawVideoData.time?.seconds
            );
          })
          setVideoList(
          videosList.map((v) => {
            return {
              ...v,
              patient: patient,
            };
          })
        );
      }
    );
    const docNotesQuery = collection(
      FirestoreDB,
      "videoLogs",
      patient?.id,
      "docNotes"
    );
    onSnapshot(docNotesQuery, (docNotesSnapshot) => {
      for (let j = 0; j < docNotesSnapshot?.docs?.length; j++) {
        docNotes.push(docNotesSnapshot.docs[j]?.id);
      }
      setDocNotes(docNotes);
    });
  };

  const handleVideoOpen = (videoDetails, patientID) => {
    setBottomDrawer({
      ...bottomDrawer,
      show: true,
      title: videoDetails?.rawVideoData?.fileName,
      patientName: videoDetails?.user?.fullname,
      patientPhoto: videoDetails?.user?.photoURL,
      content: (
        <PlayVideo
          videoList={videoList}
          handleVideoOpen={handleVideoOpen}
          typeOfUser={typeOfUser}
          videoDetails={videoDetails}
          patientID={patientID}
        />
      ),
    });
    LogAnalyticsEvent(ANALYTICS_KEYS.PATIENTOVERVIEW.VIEWALLVIDEOS,{
      eventName : "View All Videos",
      message: "View all videos, video viewed",
      videoID: videoDetails.videoId,
    })
  };

  const handleDateChange = (range) => {
    setDateRange(range || [null, null]);
  };

  const handleFilterChange = (event) => {
    setFilterOption(event.target.value);
  };

  const filterVideosByDateRange = (videoList, dateRange) => {
    if (!dateRange[0] || !dateRange[1]) return videoList;
    return videoList.filter(
      (video) =>
        video.rawVideoData.time.seconds * 1000 >= dateRange[0] &&
        video.rawVideoData.time.seconds * 1000 <= dateRange[1]
    );
  };

  const filterVideosByReviewStatus = (videoList, filterOption) => {
    if (filterOption === "All") return videoList;
    return videoList.filter(
      (video) =>
        (filterOption === "Reviewed" && video?.docNotesDocId !== "") ||
        (filterOption === "Pending" && video?.docNotesDocId === "")
    );
  };

  const filteredVideoList = filterVideosByReviewStatus(
    filterVideosByDateRange(videoList, dateRange),
    filterOption
  );

  useEffect(() => {
    if (patient?.id) {
      fetchOnePatientAllVideos(patient?.id);
    }
  }, [patient]);

   useEffect(() => {
    if (currentPatientVideos?.id) {
      setVideoList(
        currentPatientVideos?.data?.sort((a, b) => b.rawVideoData.time.seconds - a.rawVideoData.time.seconds).map((video) => {
          return {
            ...video,
            user: patient,
          };
        }) || []
      );
    }
  }, [currentPatientVideos]);  

  return (
    <>
      <Box
        className="flexBox fullWidth flexWrap video-filters"
        sx={{ justifyContent: "flex-end", gap: 1, padding: 3 }}
      >
        <FormControl sx={{ minWidth: "18%" }}>
          <InputLabel id="video-type-label">Video Type</InputLabel>
          <Select
            MenuProps={MenuProps}
            sx={{ width: 215 }}
            labelId="video-type-label"
            id="demo-simple-select"
            value={filterOption}
            label="Video Type"
            onChange={handleFilterChange}
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"Pending"}>Pending</MenuItem>
            <MenuItem value={"Reviewed"}>Reviewed</MenuItem>
          </Select>
        </FormControl>
        <DateRangePicker
          value={dateRange}
          onChange={handleDateChange}
          format="MM/dd/yyyy"
          maxDetail="month"
          calendarIcon={<FaCalendarAlt />}
          dayPlaceholder={"dd"}
          monthPlaceholder={"mm"}
          yearPlaceholder={"yyyy"}
          rangeDivider={" to "}
        />
      </Box>
      {videoList?.length > 0 ? (
        <List
          className="allVideosBox"
          sx={{
            display: { sm: "flex", md: "flex", xs: "flex" },
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {filteredVideoList.map((video, i) => {
            return (
              <ListItem
                key={video?.id || i}
                className={`${video?.docNotesDocId == "" ? "notviewed" : "viewed"
                  } flexBox flexCenter listItemBox`}
                gap={3}
                sx={{ flexWrap: "wrap" }}
              >
                <ListItemAvatar>
                  <Avatar variant="rounded" src={patient?.photoURL} />
                </ListItemAvatar>
                {/* <div style={{ position: "relative" }}>
                <div style={{ position: "absolute", top: 0, right: 1 }}>
                 
                </div>
              </div> */}
                {/* <CardMedia
                component="img"
                height="80"
                image={patient?.photoURL}
              /> */}
                <ListItemText>
                  <Typography className="username" variant="subtitle1">
                  {video?.rawVideoData?.fileName
                    ? `${video?.rawVideoData?.fileName.split(/\s+/)
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(" ")}`
                    : ''}
                  </Typography>
                </ListItemText>
                <Box
                  className="flexBox flexCenter"
                  gap={3}
                  sx={{ flexWrap: "wrap" }}
                >
                  <ListItemText
                    sx={{
                      width: { sm: "60px", md: "90px", xs: "60px" },
                      display: { md: "flex", sm: "flex", sx: "flex" },
                      flexDirection: { md: "row", sm: "column", xs: "column" },
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <Typography variant="caption">
                      <Update sx={{ marginRight: 1 }} color="success" />
                      {video?.rawVideoData?.duration?.toFixed(2)}
                    </Typography>
                  </ListItemText>
                  <ListItemText
                    sx={{
                      width: { sm: "60px", md: "90px", xs: "60px" },
                      display: { md: "flex", sm: "flex", sx: "flex" },
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="body2" className="caption">
                      {video?.time?.toDate().toLocaleDateString()}
                    </Typography>
                  </ListItemText>
                  <ListItemIcon
                    sx={{
                      minWidth: { md: "85px", sm: "85px", xs: "50px" },
                      display: { md: "flex", sm: "flex", sx: "flex" },
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {video?.docNotesDocId ? (
                      <VisibilityOutlined fontSize="small" color="success" />
                    ) : (
                      <VisibilityOffOutlined fontSize="small" color="error" />
                    )}
                  </ListItemIcon>
                </Box>
                <Box className="flexBox flexCenter" sx={{ flexWrap: "wrap" }}>
                  <Button
                    className="playButton"
                    onClick={() => {
                      handleVideoOpen(video, patient?.id);
                    }}
                  >
                    <PlayCircle />
                  </Button>
                  <Button
                    className="detailsButton"
                    variant="outlined"
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.20)",
                      borderRadius: "2.5rem",
                      color: "#000",
                      textTransform: "capitalize",
                      ":hover": {
                        border: "1px solid rgba(0, 0, 0, 0.20)",
                        bgcolor: "#e2e3e5",
                      },
                    }}
                    onClick={() => {
                      handleVideoOpen(video, patient?.id);
                    }}
                  >
                    Details
                  </Button>
                </Box>
              </ListItem>
            );
          })}
        </List>

      ) : (
        
        <>
        {!isLoading ? (
          <Box>
            <Typography
              variant="h4"
              color="secondary"
              sx={{
                fontFamily: "unset",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Retrieving Video Data.
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography
              variant="h4"
              color="secondary"
              sx={{
                fontFamily: "unset",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              No new videos found
            </Typography>
          </Box>
        )}
      </>
      
      )}
    </>
  );
};

export default ViewPatientAllVideos;
