import { React, useState, useEffect } from 'react';
import { Box, Grid, Typography, Divider } from '@mui/material';

import BlogImage from '../../../assets/images/about_page.png';
import MaskedImage from '../../../components/MaskedImage';
import Footer from '../Footer';
import './Blog.css';
import { Scrollbar } from 'smooth-scrollbar-react';
import Fade from 'react-reveal';
import PostCard from '../Blog/PostCard';
import BlogPhoto from '../../../assets/images/blog_stock_image.png';
import BlogPhoto1 from '../../../assets/images/blog_stock_image2.png';

import Partners from '../HomePage/Partners';

const Blog = () => {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const localUserInfo = localStorage.getItem('userInfo');
    if (localUserInfo) {
      setUserInfo(JSON.parse(localUserInfo));
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
    }
  }, [userInfo]);

  const handleUserInfoChange = (info) => {
    setUserInfo(info);
  };

  return (
    <Scrollbar
      plugins={{
        overscroll: {
          effect: '',
        },
      }}
    >
      <Box className="landingPageMainContainer">
        <Grid item xs={12}>
          <MaskedImage
            currentStyle={{
              height: '40vh',
              backgroundImage: `url(${BlogImage})`,
            }}
          >
            <Fade duration={1500} delay={400} left>
              <Typography
                variant="h2"
                sx={{ fontFamily: 'Lato', fontWeight: 700 }}
              >
                Our Blog
              </Typography>
            </Fade>
          </MaskedImage>
        </Grid>
        <Fade duration={1500} delay={400} bottom>
          <Grid
            container
            spacing={0}
            justifyContent={'center'}
            textAlign={'center'}
          >
            <Grid item xs={10}>
              <Fade duration={1500} delay={400} bottom>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontFamily: 'Lato',
                    fontSize: { sm: '1rem', md: '1rem', lg: '1.2rem' },
                    lineHeight: { sm: '1.5rem', md: '1.5rem', lg: '1.8rem' },
                    color: 'var(--clr-theme-gray-dark)',
                  }}
                >
                  Stay up-to-date on the latest advancements in neurological
                  care by exploring our blog!
                </Typography>
              </Fade>
            </Grid>
            <Divider
              sx={{
                borderColor: 'var(--clr-theme-purple)',
                width: '60%',
                margin: '3rem',
              }}
            />

            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              sx={{
                maxWidth: { xs: '100%', sm: '95%', md: '90%', lg: '80%' },
                margin: '0 auto',
              }}
            >
              <PostCard
                photo={BlogPhoto}
                title="Revolutionizing Autism Treatment with Video-Based Data"
                content={`
        In the rapidly evolving healthcare sector, technology isn’t a luxury but a critical driver of progress. Among the numerous innovations that define this era, the strategic use of video-based data stands out as a game-changing paradigm shift. This approach is particularly noteworthy in the realm of autism and neurological disorder treatments, offering tangible benefits and nuanced insights into patient care.

        In contrast to the conventional reliance on text-based records in healthcare, the integration of video data introduces a visual dimension to analytics. This is very important when it comes to neurological disorders such as autism, where each patient’s symptom manifestation is different and calls for a more comprehensive approach to treatment. Unlike static written descriptions, videos offer a range of insights into patients’ experiences in different environments, allowing healthcare professionals to observe, analyze, and understand behavioral patterns and responses in a more detailed and accurate manner. This enhanced observational capability paves the way for more personalized and effective interventions, ultimately improving the quality of care for individuals with neurological conditions.

        The incorporation of videos into neurological disorder treatment also facilitates the development and implementation of more robust evidence-based strategies. Unlike conventional methods that rely on subjective interpretations and trial-and-error, videos offer an objective representation of patients’ behavioral responses to treatments. This visual data serves as concrete evidence, allowing healthcare professionals to identify symptom patterns and trends that might be missed with traditional approaches. The ability to observe real-time interactions in different contexts provides a wealth of information, facilitating a more thorough analysis of treatment efficacy.

        Capturing complex behaviors and responses on video can become a powerful and invaluable tool for clinicians, providing a visual narrative that surpasses traditional written records to enable a more holistic understanding of neurological disorders.
    `}
                author="Pooja Shah"
                datePosted="September 20, 2024"
                userInfo={userInfo}
                userInfoUpdated={handleUserInfoChange}
              />

              <PostCard
                photo={BlogPhoto1}
                title="Centralized Communication: Enhancing Autism Care Efficiency"
                content={`
                                In the complex world of autism care, effective communication between healthcare providers, educators, and parents is essential. Yet, managing the flow of information across multiple platforms can be overwhelming, leading to fragmented insights and disjointed care. Bringing communication onto one unified platform can make all the difference, ensuring that every member of a child’s care team stays informed and aligned. A more streamlined approach to information sharing simplifies management and improves care quality by fostering greater collaboration and a more holistic analysis of intervention approaches.

                                The ease of access to important patient information is one of the main benefits of a centralized system. Clinicians, educators, and parents can share observations, insights, and updates in real time, allowing for a more comprehensive understanding of the child's development. For example, when a teacher notices a behavioral change at school, they can quickly communicate it to healthcare providers, who can then make timely adjustments to the intervention plan. This efficient exchange of information ensures that no critical detail is overlooked, resulting in more personalized and effective interventions.
                                
                                Another advantage of centralizing care is the ability to standardize intervention approaches across multiple settings. When everyone involved in a child’s care has access to the same information—whether it’s a behavioral update from school, a clinical assessment, or parental observations—they can align their strategies to ensure consistency. This reduces disparities in how interventions are implemented at home, school, and in therapy sessions, allowing for a more cohesive approach. Standardizing care also ensures that all professionals are on the same page, working toward the same goals with the same understanding of the child’s needs.
                                Having a vast amount of data in one place allows clinicians to extract valuable analytics from the information collected, providing deeper insights into a child's progress and the efficacy of various treatment methods. By tracking behaviors, symptoms, and treatments over time, specific patterns can emerge that provide more information about how the child is responding to different interventions. These analytics enable clinicians to make more informed decisions, identifying effective strategies and adjusting approaches as needed. The ability to extract key trends from a large volume of data ensures that intervention plans are constantly refined based on real-time evidence.
                                Centralizing communication and data management also reduces the administrative burden on caregivers by automating the generation of reports and summaries from all providers and educators. This not only saves time but also ensures that care teams have access to the most relevant and comprehensive information at all times. Moreover, this approach also alleviates the common frustrations that parents experience when juggling multiple apps, emails, and documents. Instead of sifting through scattered information, parents can rely on one location to track their child’s progress, review assessments, and stay in touch with the entire care team. This process relieves stress and allows parents to focus on advocating for their child's needs, which are supported by accurate and up-to-date information. It also encourages parents to actively contribute to their child's care by sharing their observations, making them an integral part of the decision-making process.
                                
                                Ultimately, a more integrated approach to communication enhances the overall quality of autism care. It breaks down barriers between professionals and families, fostering a collaborative environment in which everyone works toward a common goal. When communication is streamlined and accessible, decisions are better informed, interventions are more coordinated, and outcomes for children with autism improve. By bringing everyone together on the same platform, we can create a more efficient and supportive system that benefits both the child and the care team.
                                
                                
                                `}
                author="Pooja Shah"
                datePosted="September 27, 2024"
                userInfo={userInfo}
                userInfoUpdated={handleUserInfoChange}
              />
            </Grid>
          </Grid>
        </Fade>
        <Grid item xs={12}>
            <Box>
              <Partners size="small" />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Footer />
            </Box>
          </Grid>
      </Box>
    </Scrollbar>
  );
};
export default Blog;
