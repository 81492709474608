import { CardContent, Typography, Card, Box, Button, Grid, TextField } from '@mui/material';
import { React, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import NewLogo from '../../../components/BrandLogo';



const PostCard = ({ photo, title, content, author, datePosted, userInfo, userInfoUpdated }) => {

    const [open, setOpen] = useState(false);

    const [userInfoOpen, setUserInfoOpen] = useState(null);

    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [userEmail, setUserEmail] = useState("");



    const handleReadMore = () => {
        if (!userInfo) {
            setUserInfoOpen(true);
        }
        else {
            setOpen(!open);
        }
    };

    const handleUserInfo = () => {
        const user = { userFirstName, userLastName, userEmail };
        userInfoUpdated(user);
        setUserInfoOpen(false);
        setOpen(true);
        userInfoUpdated(user);
    };


    return (
        <>

            <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                sx={{
                    minWidth: '400px',
                    flexGrow: 1,
                }}
            >
                <Card className="post" sx={{ border: '14px solid #d9d9d9', height: '100%', maxWidth: '400px', width: '100%', display: 'flex', flexDirection: 'column', aspectRatio: '1 / 1.5', }}>
                    <CardContent className='flexBox flexCenter flexColumn' sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        justifyContent: 'space-between',

                    }}>
                        <Box
                            component="img"
                            src={photo}
                            alt={title}
                            sx={{
                                width: "100%",
                                height: "40%",
                                objectFit: "cover",
                            }}
                        />
                        <Typography
                            variant='h6'
                            sx={{
                                color: 'black', fontFamily: 'Lato', fontWeight: 400,
                                width: '100%', height: "10s%",
                            }}

                        >
                            {title}
                        </Typography>
                        <Typography
                            variant='body2'
                            sx={{
                                color: 'black', fontFamily: 'Karla', fontWeight: 200, height: '40%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis', textAlign: 'left',
                            }}
                        >
                            {content.length > 400 ? `${content.substring(0, 400)}...` : content}
                        </Typography>
                        <Button size="medium" onClick={handleReadMore} sx={{
                            color: '#9291F0', fontFamily: 'Karla', height: '5%', textTransform: 'none', '&:hover': {
                                textDecoration: 'underline',
                            },
                        }}>
                            Read More
                        </Button>

                    </CardContent>
                </Card>
            </Grid>


            <Dialog open={userInfoOpen && userInfo == null} onClose={() => setUserInfoOpen(false)} fullWidth maxWidth="sm" scroll="paper"
                PaperProps={{
                    sx: {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        margin: 0,

                    },
                }}

            >

                <Box sx={{ marginTop: "25px", display: 'flex', justifyContent: 'center' }}>
                    <NewLogo newline={false} />
                </Box>
                <DialogTitle>
                    <Typography
                        variant="h4"
                        align="center"
                        style={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold' }}
                    >
                        {'WELCOME TO MINDLY.'}
                    </Typography>
                </DialogTitle>

                <Typography
                    gutterBottom
                    variant="subtitle1"
                    sx={{
                        color: "var(--clr-theme-gray)",
                        fontFamily: "Karla",
                        fontWeight: 700,
                        textAlign: 'center',
                    }}
                >
                    {`Please enter your information below to receive future news and updates.`}
                </Typography>
                <Box sx={{ padding: '15px' }}>
                    <Box >
                        <Typography variant="subtitle1"
                            sx={{
                                color: "var(--clr-theme-gray)",
                                fontFamily: "Karla",
                                fontWeight: 700,

                            }}>
                            First Name
                        </Typography>
                        <TextField
                            fullWidth
                            margin="normal"
                            value={userFirstName}
                            sx={{ fontFamily: 'Karla', fontWeight: 250 }}
                            onChange={(e) => setUserFirstName(e.target.value)}
                        />
                    </Box>
                    <Box >
                        <Typography variant="subtitle1"
                            sx={{
                                color: "var(--clr-theme-gray)",
                                fontFamily: "Karla",
                                fontWeight: 700,

                            }}>
                            Last Name
                        </Typography>
                        <TextField
                            fullWidth
                            margin="normal"
                            value={userLastName}
                            sx={{ fontFamily: 'Karla', fontWeight: 250 }}
                            onChange={(e) => setUserLastName(e.target.value)}
                        />
                    </Box>
                    <Box>
                        <Typography variant="subtitle1"
                            sx={{
                                color: "var(--clr-theme-gray)",
                                fontFamily: "Karla",
                                fontWeight: 700,

                            }}>
                            Email
                        </Typography>
                        <TextField
                            fullWidth
                            margin="normal"
                            type="email"
                            value={userEmail}
                            sx={{ fontFamily: 'Karla', fontWeight: 250 }}
                            onChange={(e) => setUserEmail(e.target.value)}
                        />
                    </Box>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleUserInfo}
                            sx={{
                                backgroundColor: "#9291f0",
                                textTransform: "capitalize",
                                fontFamily: "Lato",
                                fontWeight: 400,
                                fontSize: "1.2rem",
                                width: "100%",
                            }}

                        >
                            Submit
                        </Button>
                    </div>
                </Box>

            </Dialog>

            <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xl" scroll="paper"
                PaperProps={{
                    sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        margin: 'auto',
                        width: '90%',
                        maxWidth: '90vw',
                        maxHeight: '70vh',
                        height: 'auto',
                        overflowY: 'auto',
                    },
                }}

            >


                <Button
                    size="small"
                    onClick={handleReadMore}
                    sx={{
                        color: '#9291F0',
                        fontFamily: 'Lato',
                        padding: '0',
                        minWidth: 'auto',
                        position: 'absolute',
                        top: '1rem',
                        right: '1rem',
                        textTransform: 'none',
                        fontSize: '1rem',
                    }}
                >
                    X
                </Button>


                <DialogTitle sx={{ width: '100%' }}>
                    <Typography
                        variant="h4"
                        align="center"
                        style={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold' }}
                    >
                        {title}
                    </Typography>
                </DialogTitle>

                <Box
                    sx={{
                        padding: { xs: '0 2%', sm: '0 5%', md: '0 5%' },
                        overflowY: 'auto',
                        flexGrow: 1,
                        width: '100%',
                        maxHeight: '70vh',
                    }}
                >
                    <Typography
                        variant='subtitle2'
                        gutterBottom
                        sx={{ color: 'black', fontFamily: 'Karla', fontWeight: 250 }}
                    >
                        {`Date Posted: ${datePosted}`}
                    </Typography>
                    <Box
                        component="img"
                        src={photo}
                        alt={title}
                        sx={{
                            width: "100%",
                            maxHeight: "30vh",
                            objectFit: "cover",
                        }}
                    />


                    <Typography
                        variant='body2'

                        sx={{
                            color: 'black', fontFamily: 'Karla', fontWeight: 250, fontSize: '1rem',
                            marginTop: '1.125rem',
                            marginBottom: '0.75rem',
                        }}

                    >
                        {content}
                    </Typography>
                </Box>

                <Box display="flex" justifyContent="flex-end" sx={{ padding: '1rem', position: 'relative', width: '100%' }}>
                    <Button
                        size="small"
                        onClick={handleReadMore}
                        sx={{
                            color: '#9291F0',
                            fontFamily: 'Lato',
                            padding: '0',
                            minWidth: 'auto',
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            fontSize: '1rem',
                            textTransform: 'none', '&:hover': {
                                textDecoration: 'underline',
                            },


                        }}
                    >
                        Close
                    </Button>
                </Box>
            </Dialog>
        </>
    );

};
export default PostCard;