/**
 * ContactInfo Component
 *
 * This component displays contact information including address, email, and social media links.
 *
 * @module ContactInfo
 */

import React from 'react';
import { Box, ListItem } from '@mui/material';
import { Email, FollowTheSigns, HomeWork } from '@mui/icons-material';
import FacebookIcon from '../Updated_UI/SocialLinks/Facebook';
import TwitterIcon from '../Updated_UI/SocialLinks/Twitter';
import LinkedInIcon from '../Updated_UI/SocialLinks/LinkedIn';
import ContactInfoItem from './ContactInfoItem';

/**
 * ContactInfo component to display contact information
 * @returns {React.Component} ContactInfo component
 */
const ContactInfo = () => (
  <Box className="contact-info">
    <ContactInfoItem
      icon={<HomeWork />}
      title="Address"
      content={
        <>
          1138 Desmond Ct,
          <br /> Fremont CA, 94539
        </>
      }
    />
    <ContactInfoItem
      icon={<Email />}
      title="Email"
      content="support@mindly.care"
    />
    <ContactInfoItem
      icon={<FollowTheSigns />}
      title="Follow us"
      content={
        <Box className="flexBox flexRow" sx={{ gap: 2 }}>
          <ListItem disablePadding>
            <FacebookIcon color="white" />
          </ListItem>
          <ListItem disablePadding>
            <TwitterIcon color="white" size={21} />
          </ListItem>
          <ListItem disablePadding>
            <LinkedInIcon color="white" />
          </ListItem>
        </Box>
      }
    />
  </Box>
);

export default ContactInfo;
