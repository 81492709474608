/**
 * ContactInfoItem Component
 *
 * This component displays an individual contact information item with an icon, title, and content.
 *
 * @module ContactInfoItem
 */

import React from 'react';
import { Box } from '@mui/material';

/**
 * ContactInfoItem component to display individual contact information items
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.icon - Icon to display
 * @param {string} props.title - Title of the contact info item
 * @param {React.ReactNode} props.content - Content of the contact info item
 * @returns {React.Component} ContactInfoItem component
 */
const ContactInfoItem = ({ icon, title, content }) => (
  <Box className="contact-info-item">
    <Box className="contact-info-icon">{icon}</Box>
    <Box className="contact-info-content">
      <h4>{title}</h4>
      <p>{content}</p>
    </Box>
  </Box>
);

export default ContactInfoItem;
