export const ANALYTICS_KEYS = {
  USERS: {
    DOCTORSIGNIN: "user_signin_doctor",
    PATIENTSIGNIN: "user_signin_patient",
    DOCTORGOOGLESIGNIN: "doctor_signin_google",
    PATIENTGOOGLESIGNIN: "patient_signin_google",
    FORGOTPASSWORD: "forgot_password",
    DOCTORLOGOUT: "doctor_logout",
    PATIENTLOGOUT: "patient_logout",
    DOCTORSIGNUP: "doctor_signup",
    PATIENTSIGNUP: "patient_signup",
  },

  DASHBOARD: {
    DOCTORVIDEOUPLOAD: "doctor_video_upload",
    PATIENTVIDEOUPLOAD: "patient_video_upload",
    DOCTORDOCUMENTUPLOAD: "doctor_upload_document",
    PATIENTDOCUMENTUPLOAD: "patient_upload_document",
    REMINDERS: "patient_reminders",
    DOCTORVIDEOVIEWS: "doctor_video_view",
  },

  LEFTMENU: {
    SIDEBARMENUCLICK: "sidebar_menu_click",
  },

  PATIENTSLIST: {
    PROFILEVIEW: "patient_profile_view",
    MESSAGE: "patient_profile_message",
    REMINDER: "patient_profile_reminder",
    UPDATETREATMENT: "update_treatment",
    APRROVETREATMENT: "approve_treatment",
    SAVETREATMENT: "save_treatment",
    DELETETREATMENT: "delete_treatment",
    ADDNEWTREATMENT: "add_treatment",
    UPDATETREATMENTINFORMATION: "update_treatment_info",
  },

  PATIENTOVERVIEW: {
    DOCUMENTDELETE: "patientprofile_document_delete",
    DOCUMENTVIEW: "patientprofile_document_view",
    VIEWALLVIDEOS: "patientprofile_view_all_videos",
    VIDEOVIEW: "patientprofile_video_view",
  },

  LANDINGPAGE: {
    VISITSONHOME: "visits_home",
    VISITSONABOUT: "visits_about",
    VISITSONTEAMS: "visits_teams",
    VISITSONBLOG: "visits_blog",
    VISITSONCONTACT: "visits_contact",
    PRIVACYPOLICYVIEWS: "views_privacy_policy",
    TERMSCONDITIONVIEWS: "views_terms_conditions",
  },

  PAGE: {
    NOOFVISITSONHOME: "no_of_visits_on_home",
    NOOFVISITSONABOUT: "no_of_visits_on_about",
    NOOFVISITSONTEAMS: "no_of_visits_on_teams",
    NOOFVISITSONCONTACT: "no_of_visists_on_contact",
  },

  HISTORICALDATA: {
    DOCUMENTDELETE: "document_delete",
    DOCUMENTVIEW: "document_view",
  },

  FEEDBACK: {
    VIDEOFEEDBACK: "doctor_video_feedback",
  },

  MESSAGE: {
    MESSAGEBYDOCTOR: "doctor_message",
    MESSAGEBYPATIENT: "patient_message",
    GROUPMESSAGEBYDOCTOR: "doctor_group_message",
    GROUPMESSAGEBYPATIENT: "patient_group_message",
  },

  STATISTICS: {
    PATIENTSTATSFORVIDEOS: "patient_video_stats",
    STATSVIEWBYDOCTOR: "doctor_statsview_per_patient",
    PATIENTSTATSFORRATING: "patient_rating_stats",
    PATIENTSTATSFORSYMPTOM: "patient_symptom_stats",
    PATIENTSTATSFORFREQUENCY: "patient_frequency_stats",
  },

  SETTINGS: {
    PASSWORDCHANGE: "password_change",
    DOCTORACCOUNTDELETE: "doctor_account_delete",
    PATIENTACCOUNTDELETE: "patient_account_delete",
    DOCTORADDPATIENT: "doctor_add_patient",
    PATIENTADDDOCTOR: "patient_add_doctor",
    DOCTORPROFILEUPDATE: "doctor_profile_update",
    PATIENTPROFILEUPDATE: "patient_profile_update",
  },
  COUNT: {
    DOCTORSCOUNT: "doctors_count",
    PATIENTSCOUNT: "patients_count",
    VIDEOSCOUNT: "videos_count",
    DOCUMENTSCOUNT: "documents_count",
  },
};
