import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { FirestoreDB } from "../../../utils/firebase/firebase";
import { fetchUserVideoActivityLogs } from "../../../api";
import Progress from "../../Progress";

import "./VideoActivityLogs.css";

const VideoActivityLogs = ({ patientID, user, typeOfUser, videoDetails }) => {
  const [logsData, setLogsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchLogs = async () => {
    try {
      const results = await fetchUserVideoActivityLogs(
        patientID || user?.uid,
        videoDetails?.videoId
      );
      setLogsData(results);
    } catch (error) {
      console.error("Error fetching logs:", error);
    } finally {
      // Set loading state to false when the data fetching is complete (whether successful or not)
      setIsLoading(false);
    }
  };

  console.log("VideoDeatils", videoDetails.videoId);

  // const fetchLogs = () => {
  //   onSnapshot(
  //     query(
  //       collection(FirestoreDB, "logs", patientID, "videoActivityLogs"),
  //       where("videoId", "==", videoDetails?.videoId)
  //     ),
  //     (logsSnapshot) => {
  //       const dataArray = [];
  //       logsSnapshot.forEach((doc) => {
  //         const data = doc.data();
  //         dataArray.push({ ...data, id: doc.id });
  //       });
  //       setLogsData(dataArray);
  //     }
  //   );
  // };
  console.log(logsData, "logsdata");

  useEffect(() => {
    fetchLogs();
  }, [patientID, videoDetails?.videoId]);

  // if (isLoading) return <Progress />;

  return (
    <Box p={3} className="flexBox flexColumn Auditlog" sx={{ gap: 2 }}>
      <Typography
        variant="h4"
        sx={{
          fontFamily: "Lato",
          fontWeight: 600,
          color: "var(--clr-theme-purple)",
        }}
      >
        Activity Logs
      </Typography>
      {isLoading && <Progress />}
      <TableContainer
        component={Paper}
        elevation={4}
        className="tableContainer"
      >
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          className="table"
        >
          <TableHead className="tableHead">
            <TableRow>
              <TableCell align="left">Performed By</TableCell>
              <TableCell align="center">User Type</TableCell>
              <TableCell align="center">Action performed</TableCell>
              <TableCell align="center">Log updated</TableCell>
              <TableCell align="center">Reference ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {isLoading ? ( // Check if loading is in progress
              <TableRow>
                <TableCell
                  colSpan={5}
                  align="center"
                  sx={{ fontFamily: "Lato", fontWeight: 700 }}
                >
                  Loading...
                </TableCell>
              </TableRow>
            ) : logsData?.length === 0 || !logsData ? (
              <TableRow>
                <TableCell
                  colSpan={5}
                  align="center"
                  sx={{ fontFamily: "Lato", fontWeight: 700 }}
                >
                  No data available
                </TableCell>
              </TableRow>
            ) : (
              logsData?.map((row) => (
                <TableRow key={row?.id} hover>
                  <TableCell component="th" scope="row">
                    {row?.userName}
                  </TableCell>
                  <TableCell align="center">{row.userType}</TableCell>
                  <TableCell align="left">
                    {row.description || "Logs not updated"} &nbsp; <br />
                    {row?.notes && <b>Notes : </b>}
                    {(row?.notes && row?.notes) || ""}
                    {row?.treatments?.map((treatment, index) => (
                      <div key={`treatment_${index}`}>
                        <div>
                          <strong>Treatment #{index + 1}</strong>
                        </div>
                        <div>
                          <strong>Diagnosis : </strong> {treatment?.diagnosis}
                        </div>
                        <div>
                          <strong>Symptom : </strong> {treatment?.symptom}
                        </div>

                        {/* Medication Information */}
                        <div>
                          <strong>Medication : </strong>
                          {treatment?.meds.map((med, medIndex) => (
                            <div key={`med_${medIndex}`}>
                              Medication Name: {med?.drug_name}
                              Dosage: {med?.dosage}
                            </div>
                          ))}
                        </div>

                        {/* Therapy Information */}
                        <div>
                          <strong>Therapy : </strong>
                          {treatment.therapies.map((therapy, therapyIndex) => (
                            <div key={`therapy_${therapyIndex}`}>
                              Therapy Name : {therapy.therapy_name}
                              Sessions Per Week : {therapy.sessionsPerWeek}
                              {/* Display other therapy information as needed */}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </TableCell>

                  <TableCell align="center">
                    {row.creationTime
                      ? new Date(row.creationTime).toLocaleString("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          // timeZoneName: "short",
                        })
                      : "-"}
                  </TableCell>
                  <TableCell align="center">{row.id}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default VideoActivityLogs;
